<template>
	<v-container class="mt-5">
    <v-row class="mb-12">
    	<v-col cols="12">
    		<v-text-field
	        label="Buscar"
	        id="id"
	        v-model="search"
	        hide-details
	        rounded
	        outlined
	        dense
	        append-icon="mdi-magnify"
	      ></v-text-field>
    	</v-col>

      <v-col cols="12" sm="5" md="4" lg="3" xl="2"  v-for="(item, i) in filterProductos" :key="i">
        <v-card class="shadowCard lateral" height="100%">

          <!-- IMAGEN PRINCIPAL -->
          <v-img 
            :src="url + item.foto" 
            height="150px"
            contain 
            v-if="item.foto"
            @click="ver(item)"
            class="white"
          >
          </v-img>
          
          <!-- SI NO HAY IMAGEN INCERTO DEFAULT-->
          <v-img 
            :src="`${url + photo}`" 
            height="150px"
            contain 
            v-else 
            @click="ver(item)" 
            color="secondary" 
          />

          <v-card-text class="pb-4" style="height: 110px">
            <span class="text-subtitle-1 font-weight-bold">{{item.nomart}}</span>
          </v-card-text>
        
          <v-card-actions class="subtitle-1 font-weight-bold secondary--text py-0" >
            <v-spacer></v-spacer>
            <v-btn 
              color="primary" 
              absolute
              button
              right
              @click="agregar_a_carrito(item)"
              class="v-btn--example"
              outlined
            >
              <v-icon>mdi-cart</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container >

</template>

<script>
import axios from 'axios';
  import {mapGetters, mapActions} from 'vuex'
  import metodos from '@/mixins/metodos.js';
  import validarErrores  from '@/mixins/validarErrores'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  export default {
    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, metodos ],

    data(){
      return{
        model: null,
        productos:[],
        alerta: { activo: false, texto:'', color:'error' },
        url   : axios.defaults.baseURL + 'imagenes-tienda/' ,
        photo : 'nophoto.png',
        search:'',
        // Alertas
        parametros:{
          dialogError: false,
          mensaje: '',
          color: ''
        },

        respuestaAlerta:false,
        loader: true,
        cargar: false,
        habil_compra:false
      }
    },


    computed:{
      ...mapGetters('login',['getusuarioFetish']),

      tamanioPantalla () {
        return this.$vuetify.breakpoint.height -50
      },

      filterProductos( ){
      	if(this.productos){
          return this.productos.filter((art)=>{
            var nom = art.nomart.toLowerCase();
            return nom.match(this.search.toLowerCase());
          })
        }
      }
    },

    created(){
      this.init();
      this.initialize()
    },

    methods:{
      ...mapActions('Carrito',['carritoadd','CerrarCarrito']),

      init(){
        this.$http.get('obtener.articulos.random').then( response =>{
          this.productos = response.data
        })
      },

      agregar_a_carrito(item){
        if( !this.habil_compra ){
          this.validarErrorDirecto('El día de compras son los Martes :(')
          return true
        }

        const payload = new Object({
          id           : item.id,
          codigo       : item.codigo,
          nomart       : item.nomart, 
          precio1      : item.precio1,
          pjedesc      : item.pjedesc,
          descrip      : item.descrip,
          foto         : item.foto,
          cantidad     : 1
        })

        this.carritoadd(payload).then( response =>{
          this.ver_carrito();
        })
      },

      initialize ( ){
        this.cargar = true
        this.habil_compra = false
        this.$http.get('articulos.compra.habil').then(response=>{
          this.habil_compra = true
        }).catch( error =>{
          this.habil_compra = false
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

    } 
    
  }
</script>

<style>
  .lateral .v-btn--example {
    bottom: 0;
    position: absolute;
    margin: 0 0 16px 0px;
    padding: 0px !important;
    min-width: 50px !important;
  }

  .lateral .v-btn--descuento{
    bottom: 0;
    position: absolute;
    margin: 0 0 0px 0px;
    min-width: 50px !important;
    z-index: 1;
  }

</style>